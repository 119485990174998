<template>
  <div class="classHour" v-loading="loading">
    <el-tabs v-model="activeName"  class="el-nav" @tab-click="handleClick">
      <div class="el-nav-header flex-nowrap">
        <img :src="courseInfo.pc_cover_picture" class="table-img" alt="">
        <div class="header-r">
          <div class="table-item-name">{{ courseInfo.title }}</div>
          <div class="table-item-times flex-nowrap">
            <div class="table-item-time" v-if="!courseInfo.is_share">学习人数：{{ courseInfo.buy_num + courseInfo.learn_num > 9999 ? ((courseInfo.buy_num+ courseInfo.learn_num) / 10000 ).toFixed(2) + 'W': (courseInfo.buy_num+ courseInfo.learn_num) }}人学习<span style="color: #D0D6DE;margin: 0 8px">|</span></div>
            <div class="table-item-time">课程时长：{{ courseInfo.length ? $carbon.formatSeconds(courseInfo.length) : 0 }}</div>
          </div>
          <div>
            <el-tag
              style="margin-right: 20px;background:rgba(255, 165, 9, 0.1);border: unset;color: #FFA509;border-radius: 10px;padding: 0 10px;"
              v-for="(item,index) in courseInfo.tags"
              :key="index"
              size="mini"
              type="success"
              effect="dark">
              {{ item.name }}
            </el-tag>
          </div>
        </div>
      </div>
      <el-tab-pane label="课时管理" class="first" name="chapters"></el-tab-pane>
      <el-tab-pane label="课程媒体" class="two" name="media">
        <!--        搜索查询-->
        <div class="search space-between">
          <div class="flex-nowrap">
            <el-select v-model="options" placeholder="请选择" class="search-type" >
              <el-option
                  v-for="item in optionsData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <el-input v-model="keywords" placeholder="请输入查询条件" class="search-input"></el-input>
            <el-button type="primary" class="search-btn" @click="searchSubmit">查询</el-button>
            <el-button class="search-btn reset" @click="resetFun">重置</el-button>
          </div>

          <!--  媒体上传  -->
          <upload-video :config="videoConfig" @uploadStatus="videoStatus" v-if="!courseInfo.is_share"></upload-video>
        </div>

        <div class="total vertical-center">
          <div class="total-left">共有 <span class="total-left-text">{{mediaData.meta ? mediaData.meta.total : 0}}</span> 条数据</div>
          <el-button type="text" class="total-right vertical-center" @click="batchDel" v-if="mediaData.data && mediaData.data.length && !courseInfo.is_share">
            <img src="~assets/images/index/del.png" alt="" style="vertical-align: bottom;" class="del">
            <img src="~assets/images/index/del-hover.png" alt="" style="vertical-align: bottom;" class="del-hover">
            <span style="margin-left: 8px">批量删除</span>
          </el-button>
          <div class="total-refresh vertical-center"  @click="searchSubmit">
            <img src="~assets/images/index/refresh.png" alt="" class="refresh">
            <img src="~assets/images/index/refresh-h.png" alt="" class="refresh-h">
            刷新
          </div>
        </div>

        <!--        表格-->
        <div v-if="mediaData.data && mediaData.data.length">
          <el-table
              class="table"
              ref="multipleTable"
              :data="mediaData.data"
              tooltip-effect="dark"
              style="width: 100%"
              @selection-change="handleSelectionChange"
              :header-cell-style="{background:'#F6F8F9',color: '#1C1F21',fontWeight:'400'}"
              border>
            <el-table-column
                type="selection"
                label="全选"
                width="90"
                :selectable="checkSelecTable"
                align="center">
            </el-table-column>

            <el-table-column
                label="媒体信息"
                show-overflow-tooltip>
              <template slot-scope="scope">
                <img :src="scope.row.cover_url ? scope.row.cover_url : courseInfo.pc_cover_picture" class="table-img"  alt="">
                <div class="table-item-name white-space" style="max-width: 65%">{{scope.row.name}}</div>
                <div class="table-item-tag vertical-center">
                  <span>格式：{{scope.row.mime_type}}</span>
                  <span>&nbsp; | &nbsp;</span>
                  <span>原片大小：{{((scope.row.size/1024)).toFixed(2)}}MB</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
                label="时长"
                width="130">
              <template slot-scope="scope"><span class="">{{ $carbon.formatSeconds(scope.row.length) }}</span></template>
            </el-table-column>
            <el-table-column
                prop="created_at"
                label="上传时间"
                width="170">

            </el-table-column>
            <el-table-column
                label="操作"
                align="center"
                width="120"
                v-if="!courseInfo.is_share">
              <template slot-scope="scope">
                <el-button  type="text" size="small" class="table-edit" @click="editMedia(scope.row)">编辑</el-button>
                <span v-if="!scope.row.lesson"><span style="color: #D0D6DE;margin: 0 8px">|</span><el-button  type="text" size="small" class="table-del" @click="delVideo(scope.row)">删除</el-button></span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <empty-prompt v-else></empty-prompt>


        <el-pagination
            v-if="mediaData.meta && mediaData.meta.last_page > 1"
            class="page"
            background
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout=" prev, pager, next,total, jumper"
            :total="mediaData.meta ? mediaData.meta.total : 0">
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="资料管理" class="three" name="materials"></el-tab-pane>
      <el-tab-pane :label="courseInfo.comment_count ? (courseInfo.comment_count > 99 ?  '留言 '+'99+' : '留言 '+courseInfo.comment_count) : '留言 '+ 0 " class="four" name="interact"  v-if="!courseInfo.is_share"></el-tab-pane>
      <el-tab-pane label="学员管理" class="five" name="student"  v-if="!courseInfo.is_share"></el-tab-pane>
    </el-tabs>

    <!--    新增修改弹框-->
    <el-dialog
      class="dialog"
      title="编辑音/视频"
      :visible.sync="isDialogVisible"
      top="30vh"
      width="540px"
      :destroy-on-close="true"
       >
      <!--  表单-->
      <el-form label-position="right" label-width="100px" class="form">
        <el-form-item label="音/视频名称">
          <el-input v-model="name" maxlength="100" placeholder="请输入音/视频名称"></el-input>
        </el-form-item>
        <el-form-item label="格式">
            <p>{{current_info.mime_type}}</p>
        </el-form-item>
        <el-form-item label="大小">
          <p>{{((current_info.size/1024)/1024).toFixed(2)}}M</p>
        </el-form-item>
        <el-form-item label="时长">
          <p>{{ $carbon.formatSeconds(current_info.length) }}</p>
        </el-form-item>
        <el-form-item label="创建时间">
          <p>{{current_info.created_at}}</p>
        </el-form-item>
        <el-form-item label="修改时间">
          <p>{{current_info.updated_at}}</p>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer form-operation" >
        <el-button class="form-operation-item" round @click="isDialogVisible = false">取 消</el-button>
        <el-button class="form-operation-item" round type="primary"  @click="mediaSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import uploadVideo from "components/uploadVideo";
import EmptyPrompt from "components/EmptyPrompt";
export default {
  name: "Media",
  data() {
    return {
      loading:true,
      activeName: 'media',
      uuid: '',
      courseInfo: {}, // 获取课程信息
      mediaData: {},  // 课程媒体
      page: 1,
      pageSize: 20,
      options: '',  // 选项
      optionsData: [
        {
          label: '标题',
          value: 'name'
        },
        /*{
          label: 'ID',
          value: 'id'
        }*/
      ],
      keywords: '', // 关键词
      multipleSelection: [], // 多选值
      videoDialog: false, // 视频上传弹框
      videoConfig: {},
      isDialogVisible: false ,// 编辑音视频
      name: '', //  媒体名称
      current_info: {}, // 当前媒体对象
    }
  },
  created() {
    if (this.$route.params.uuid) {
      this.videoConfig= {
        uuid: this.$route.params.uuid,
        type: 'course'
      }
      this.uuid = this.$route.params.uuid
      this.queryFun()
      this.getCourses()
      this.getMedia()
    }
  },
  methods: {
    // 获取课程信息
    getCourses() {
      let url = this.$api.Courses + '/' + this.uuid;
      this.$http.get(url, true).then(res => {
        if (res.data.success) {
          this.courseInfo = res.data.data;
          this.loading = false
        }
      }).catch(err =>{
        this.loading = false
      })
    },
    // 获取课程媒体
    getMedia() {
      this.loading = true
      let url= this.$api.Medias + `?page=${this.page}&pageSize=${this.pageSize}&options=${this.options}&keywords=${this.keywords}&type=course&model_uuid=${this.uuid}`;
      this.$http.get(url, true).then(res => {
        if(res.data.success) {
          this.mediaData= res.data.data
          this.loading = false
        }
      }).catch(err =>{
        this.loading = false
      })
    },
    // 批量删除
    batchDel() {
      if(this.multipleSelection.length === 0) {
        return false
      }
      this.$confirm('这是您操作比较重要的数据, 你确定要删除该内容么？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let media_ids_arr= []
        for (let i=0;i< this.multipleSelection.length; i++) {
          media_ids_arr.push(this.multipleSelection[i].id)
        }
        let url= this.$api.batch_destroy
        let data= {
          media_ids: media_ids_arr
        }
        this.$http.post(url,  data, true).then(res =>{
          if(res.data.success) {
            this.multipleSelection= []
            this.$message.success('删除成功')
            this.getMedia()
          }else {
            this.$message.error(res.data.message)
          }
        }).catch(error =>{
          this.$message.error(error.response.data.message)
        })
      }).catch(() => {});
    },
    // 删除视频
    delVideo(data) {
      this.$confirm('这是您操作比较重要的数据, 你确定要删除该内容么？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let url= this.$api.Medias + '/' + data.id;
        this.$http.del(url, true).then(res =>{
          if(res.data.success) {
            this.getMedia()
            this.$message.success('删除成功')
          }else {
            this.$message.error(res.data.message)
          }
        }).catch(error =>{
          this.$message.error(error.response.data.message)
        })
      }).catch(() => {});
    },
    // 编辑当前视频
    editMedia(data) {
      this.current_info= data;
      this.isDialogVisible= true
      this.name= data.name
    },
    // 编辑视频提交
    mediaSubmit() {
      if(this.name == '') {
        this.$message.error('请输入媒体名称')
        return false
      }
      let url= this.$api.Medias + '/' + this.current_info.id
      this.$http.put(url, {name: this.name}, true).then(res =>{
        if(res.data.success) {
          this.isDialogVisible= false
          this.$message.success('修改成功')
          this.getMedia()
        }else {
          this.$message.error(res.data.message)
        }
      }).catch(error =>{
        this.$message.error(error.response.data.message)
      })

    },
    // 当前页码
    handleCurrentChange(val) {
      this.page= val
      this.linkFun()
      this.getMedia()
    },
    // 选项多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 判断是否可以选中
    checkSelecTable(row) {
      return row.lesson === null
    },
    // 重置
    resetFun() {
      this.options= ''
      this.keywords= ''
      this.searchSubmit()
    },
    // 查询
    searchSubmit() {
      this.page= 1;
      this.linkFun();
      this.getMedia()
    },
    // 视频上传状态
    videoStatus(data) {
      if(data) {
        this.linkFun();
        this.getMedia()
      }
    },
    // 改变路由参数
    linkFun() {
      this.$router.push({path: `/courses/class/${this.uuid}/media`,query: {
          options: this.options,
          keywords: this.keywords,
          level: this.level,
          status: this.status,
          page: this.page,
          pageSize: this.pageSize
        }})
    },
    // 参数赋值
    queryFun() {
      let query= this.$route.query;
      if(query) {
        if(query.options) {
          this.options= query.options;
        }
        if(query.keywords) {
          this.keywords= query.keywords;
        }
        if(query.level) {
          this.level= query.level;
        }
        if(query.status) {
          this.status= query.status;
        }
        if(query.page) {
          this.page= parseInt(query.page);
        }
        if(query.pageSize) {
          this.pageSize= parseInt(query.pageSize);
        }
      }
    },
    // tab切换想像
    handleClick(tab, event) {
      if(tab.name === 'chapters') {
        this.$router.push({path: `/courses/class/${this.uuid}/chapters`})
      }else if(tab.name === 'media') {
        this.$router.push({path: `/courses/class/${this.uuid}/media`})
      }else if(tab.name === 'materials') {
        this.$router.push({path: `/courses/class/${this.uuid}/materials`})
      }else if(tab.name === 'interact') {
        this.$router.push({path: `/courses/class/${this.uuid}/interact`})
      }else if(tab.name === 'student') {
        this.$router.push({path: `/courses/class/${this.uuid}/student`})
      }else {
        this.$router.push({path: `/courses/courses`})
      }
    }
  },
  components: {
    uploadVideo,
    EmptyPrompt
  }
}
</script>

<style lang="less" scoped>
@import "~assets/less/courses/classHour/index.less";
.el-tab-pane{
  min-height: 528px;
}
</style>
