<template>
  <div>
    <el-button type="warning" size="small" icon="el-icon-plus" @click="addMedia" class="xz">新增</el-button>
    <el-dialog title="上传视频" width="540px" class="video-dialog-box" :visible.sync="videoUploadDialog" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false">
      <el-upload
        class="upload-video-btn"
        action=""
        :limit="config.limit_num ? config.limit_num : 5"
        :auto-upload="false"
        :on-change="handleChange"
        :on-remove="removeVideo"
        :on-exceed="exceedVideo"
        :file-list="fileList"
        v-if="fileList.length > 0 && upload_sub_status"
        accept="video/mp4"
        multiple
      >
        <el-button size="medium" style="background: rgba(255, 165, 9, 1); border: 1px solid rgba(255, 165, 9, 1)" type="primary">添加视频</el-button>
      </el-upload>
      <el-table class="video-table" :data="fileList" border style="width: 100%">
        <el-table-column label="视频名称">
          <template slot-scope="scope">
            <div class="white-space">{{ scope.row.raw ? scope.row.raw.name : '' }}</div>
          </template>
        </el-table-column>
        <el-table-column label="格式" width="100">
          <template slot-scope="scope"
            ><span>{{ scope.row.raw ? scope.row.raw.type : '' }}</span></template
          >
        </el-table-column>
        <el-table-column width="100" label="大小">
          <template slot-scope="scope">
            <span>
              {{ scope.row.raw ? (scope.row.raw.size / 1024 / 1024).toFixed(2) + '.MB' : '' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column width="60" label="进度">
          <template slot-scope="scope">
            <span> {{ scope.row.progress && scope.row.progress > 0 ? scope.row.progress : 0 }}% </span>
          </template>
        </el-table-column>
        <el-table-column width="60" label="操作">
          <template slot-scope="scope">
            <span @click="tabDel(scope.$index)" class="tab-del red-font-color">删除</span>
          </template>
        </el-table-column>
      </el-table>

      <el-upload
        class="upload-video-box"
        drag
        action=""
        :limit="config.limit_num ? config.limit_num : 5"
        :auto-upload="false"
        :on-change="handleChange"
        :on-remove="removeVideo"
        :on-exceed="exceedVideo"
        :file-list="fileList"
        v-if="fileList.length === 0"
        accept="video/mp4"
        multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传video文件，一次最多上传{{ config.limit_num ? config.limit_num : 5 }}个</div>
      </el-upload>
      <div class="progress-box">
        <!--      <el-progress :percentage="parseInt(progress_num)" v-if="progress_num > 0 && parseInt(progress_num) != 100"></el-progress>-->
        <div class="sub-btn" v-if="fileList.length > 0 && upload_sub_status">
          <el-button type="primary" size="medium" @click="submitUpload">开始上传</el-button>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="cancelUploadVideo" v-if="!upload_sub_status">暂 停</el-button>
        <el-button size="medium" @click="cancelUpload">关 闭</el-button>
      </span>
      <input type="text" v-model="config.uuid" hidden id="video_uuid" />
      <input type="text" v-model="config.type" hidden id="video_type" />
    </el-dialog>
  </div>
</template>

<script>
import TcVod from 'vod-js-sdk-v6';
import axios from 'axios';

import _ from 'lodash';
// https://apicircle.youlika.net/api/v1/
function getSignature() {
  return axios
    .post(
      'demand/upload/signature',
      {
        uuid: document.getElementById('video_uuid').value,
        type: document.getElementById('video_type').value,
      },
      {
        headers: {
          Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('access_token'),
          ['circle-uuid']: localStorage.getItem('circle-uuid'),
        },
      },
    )
    .then(function (response) {
      return response.data.data.sign;
    });
}
export default {
  name: 'uploadVideo',
  props: ['config'],
  data() {
    return {
      videoUploadDialog: false,
      uploaderInfos: [],
      vcExampleVideoName: '',
      vcExampleCoverName: '',
      cExampleFileId: '',
      fileList: [],
      progress_num: 0,
      upload_sub_status: true,
      uploader: null, // 视频事件
    };
  },
  created() {
    let _this = this;
    if (_this.config) {
      this.tcVod = new TcVod({
        getSignature: getSignature,
      });
    }
  },

  methods: {
    // 新增选择
    addMedia() {
      this.videoUploadDialog = true;
    },
    // 视频选择
    handleChange(file, fileList) {
      if (file.raw.type === 'video/mp4') {
        this.fileList = fileList;
      } else {
        this.$message.error('上传视频只能是MP4格式');
      }
    },
    // 关闭上传弹框
    cancelUpload: _.debounce(function () {
      if (!this.upload_sub_status) {
        this.$message.warning('正在上传中，请不要关闭');
        return false;
      }
      this.videoUploadDialog = false;
    }, 500),
    // 删除视频时
    removeVideo(file, fileList) {
      this.fileList = fileList;
    },
    // 超出视频事件
    exceedVideo(files, fileList) {
      let _this = this;
      let current_files = {
        name: files[0].name,
        percentage: 0,
        size: files[0].size,
        status: 'ready',
        uid: files[0].uid,
        raw: files[0],
      };
      if (files[0].type === 'video/mp4') {
        this.fileList.splice(_this.fileList.length - 1, 1, current_files);
      } else {
        this.$message.error('上传视频只能是MP4格式');
      }
    },
    // 视频上传确认
    submitUpload() {
      let _this = this;

      for (let index = 0; index < _this.fileList.length; index++) {
        _this.UploadFun(_this.fileList[index]);
      }
    },
    UploadFun(file) {
      let _this = this;
      this.uploader = this.tcVod.upload({
        mediaFile: file.raw, // 媒体文件（视频或音频或图片），类型为 File
      });
      this.uploader.on('media_progress', function (info) {
        _this.progress_num = info.percent * 100;
        _this.$set(file, 'progress', parseInt(info.percent * 100));
        _this.upload_sub_status = false;
      });
      //视频上传完成时;
      this.uploader.on('media_upload', function (info) {
        for (let i = 0; i < _this.fileList.length; i++) {
          _this.fileList.splice(i, 1);
        }
        if (_this.fileList.length > 0) {
          _this.upload_sub_status = true;
        }
        if (_this.fileList.length == 0) {
          _this.videoUploadDialog = false;
          _this.upload_sub_status = true;
          setTimeout(() => {
            _this.$emit('uploadStatus', true);
          }, 2000);
        }
        // if (_this.fileList.length > 0) {
        //   // _this.fileList.splice(0, 1);
        //   for (let i = 0; i < _this.fileList.length; i++) {
        //     _this.fileList.splice(i, 1);
        //   }
        //   if (_this.fileList.length > 0) {
        //     _this.upload_sub_status = true;
        //   } else {
        //     _this.videoUploadDialog = false;
        //     _this.upload_sub_status = true;
        //     setTimeout(() => {
        //       _this.$emit('uploadStatus', true);
        //     }, 2000);
        //   }
        // } else {
        //   _this.videoUploadDialog = false;
        //   _this.upload_sub_status = true;
        //   setTimeout(() => {
        //     _this.$emit('uploadStatus', true);
        //   }, 2000);
        // }
      });
    },
    // 取消上传
    cancelUploadVideo() {
      this.upload_sub_status = true;
      this.progress_num = 0;
      this.uploader.cancel();
    },
    // 表格删除列表
    tabDel: _.debounce(function (index) {
      if (!this.upload_sub_status && index === 0) {
        this.$message.warning('正在上传中，请不要关闭');
        return false;
      }
      this.fileList.splice(index, 1);
    }, 500),
  },
};
</script>

<style scoped>
.upload-video-box >>> .el-upload {
  width: 100%;
  margin: 0 auto;
  display: block;
}
.upload-video-box >>> .el-upload .el-upload-dragger {
  width: 100%;
}
.upload-video-box >>> .el-upload__tip {
  width: 100%;
  margin: 12px auto;
}
.upload-video-box >>> .el-upload-list {
  width: 100%;
  margin: 0 auto;
}
.progress-box {
  width: 100%;
  margin: 0 auto;
}
.sub-btn {
  margin-top: 12px;
}
.el-table >>> .el-table__empty-block {
  display: none;
}
.upload-video-btn {
  margin-bottom: 12px;
}
.upload-video-btn >>> .el-upload-list {
  display: none;
}
.upload-video-box >>> .el-upload-list {
  display: none;
}
.tab-del {
  cursor: pointer;
}
.xz {
  width: 80px;
  height: 36px;
  background: #ffa509;
  border-radius: 6px;
  border: unset;
}
.video-dialog-box {
  font-weight: 400;
}
.video-dialog-box >>> .el-dialog {
  border-radius: 10px;
}
.video-dialog-box >>> .el-dialog .el-dialog__header {
  padding: 15px 20px;
  font-weight: 400;
}
.video-dialog-box >>> .el-dialog .el-dialog__header .el-dialog__title {
  font-size: 16px;
  font-weight: bold;
  color: #1c1f21;
  line-height: 1;
  display: block;
}
.video-dialog-box >>> .el-dialog .el-dialog__header:after {
  content: '';
  display: block;
  position: relative;
  width: 100%;
  height: 1px;
  background: #ebeff2;
  bottom: -20px;
}
.video-table >>> thead tr th {
  font-weight: 400;
  padding: 5px 0;
}
</style>
